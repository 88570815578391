/* Prevent any hide animations when the page is still loading {{{ */
/* This is an insane fix and I honestly can't believe its still an issue in angular-animate 1.5.x *
/* See - http://stackoverflow.com/questions/15839479/nganimate-on-ngshow-preventing-animation-when-it-starts-first-time#25562457 */
body.router-routing .animate {
	animation-duration: 0s !important;
}
/* }}} */

/* .animate - base animation class {{{ */
.animate {
	animation-timing-function: ease-out;
	animation-duration: 0.5s;
}
/* }}} */

/* .animate-duration-* - durations {{{ */
.animate.animate-duration-0 { animation-duration: 0 }
.animate.animate-duration-10ms { animation-duration: 0.01s }
.animate.animate-duration-20ms { animation-duration: 0.02s }
.animate.animate-duration-30ms { animation-duration: 0.03s }
.animate.animate-duration-40ms { animation-duration: 0.04s }
.animate.animate-duration-50ms { animation-duration: 0.05s }
.animate.animate-duration-100ms { animation-duration: 0.1s }
.animate.animate-duration-200ms { animation-duration: 0.2s }
.animate.animate-duration-500ms { animation-duration: 0.5s }
.animate.animate-duration-1s { animation-duration: 1s }
.animate.animate-duration-2s { animation-duration: 2s }
.animate.animate-duration-5s { animation-duration: 5s }
.animate.animate-duration-10s { animation-duration: 10s }
.animate.animate-duration-20s { animation-duration: 20s }
.animate.animate-duration-30s { animation-duration: 30s }
/* }}} */
/* .animate-easing-* - easings {{{ */
.animate.animate-easing-linear { animation-timing-function: linear }
.animate.animate-easing-ease { animation-timing-function: ease }
.animate.animate-easing-ease-in { animation-timing-function: ease-in }
.animate.animate-easing-ease-out { animation-timing-function: ease-out }
.animate.animate-easing-ease-in-out { animation-timing-function: ease-in-out }
/* }}} */
/* .animate-repeat-* - repeat/looping {{{ */
.animate.animate-repeat-1 { animation-iteration-count: 1 }
.animate.animate-repeat-2 { animation-iteration-count: 2 }
.animate.animate-repeat-3 { animation-iteration-count: 3 }
.animate.animate-repeat-4 { animation-iteration-count: 4 }
.animate.animate-repeat-5 { animation-iteration-count: 5 }
.animate.animate-repeat-loop { animation-iteration-count: infinite }
/* }}} */
/* .animate-delay-* - delays {{{ */
.animate.animate-delay-0ms { animation-delay: 0 }
.animate.animate-delay-10ms { animation-delay: 0.01s }
.animate.animate-delay-20ms { animation-delay: 0.02s }
.animate.animate-delay-30ms { animation-delay: 0.03s }
.animate.animate-delay-40ms { animation-delay: 0.04s }
.animate.animate-delay-50ms { animation-delay: 0.05s }
.animate.animate-delay-100ms { animation-delay: 0.1s }
.animate.animate-delay-200ms { animation-delay: 0.2s }
.animate.animate-delay-500ms { animation-delay: 0.5s }
.animate.animate-delay-1s { animation-delay: 1s }
.animate.animate-delay-2s { animation-delay: 2s }
.animate.animate-delay-5s { animation-delay: 5s }
.animate.animate-delay-10s { animation-delay: 10s }
.animate.animate-delay-20s { animation-delay: 20s }
.animate.animate-delay-30s { animation-delay: 30s }
/* }}} */

/* .animate-fade {{{ */
@keyframes animate-fade {
	from { opacity: 0 }
	to { opacity: 1 }
}

.animate.animate-fade.ng-hide-remove,
.animate.animate-fade.ng-enter
{
	animation-name: animate-fade;
}

.animate.animate-fade.ng-hide-add,
.animate.animate-fade.ng-leave-active
{
	animation-name: animate-fade;
	animation-direction: reverse;
}
/* }}} */
/* .animate-fly-up {{{ */
@keyframes animate-fly-up {
	from {
		opacity: 0;
		transform: translateY(2em);
	}
	to {
		transform: translateY(0);
	}
}

.animate.animate-fly-up.ng-hide-remove,
.animate.animate-fly-up.ng-enter
{
	animation-name: animate-fly-up;
}

.animate.animate-fly-up.ng-hide-add,
.animate.animate-fly-up.ng-leave-active
{
	animation-name: animate-fly-up;
	animation-direction: reverse;
}
/* }}} */
/* .animate-fly-down {{{ */
@keyframes animate-fly-down {
	from {
		transform: translateY(-2em);
		opacity: 0;
	}
	to {
		transform: translateY(0);
	}
}

.animate.animate-fly-down.ng-hide-remove,
.animate.animate-fly-down.ng-enter
{
	animation-name: animate-fly-down;
}

.animate.animate-fly-down.ng-hide-add,
.animate.animate-fly-down.ng-leave-active
{
	animation-name: animate-fly-down;
	animation-direction: reverse;
}
/* }}} */
/* .animate-fly-left {{{ */
@keyframes animate-fly-left {
	from {
		transform: translateX(-2em);
		opacity: 0;
	}
	to {
		transform: translateX(0);
	}
}

.animate.animate-fly-left.ng-hide-remove,
.animate.animate-fly-left.ng-enter
{
	animation-name: animate-fly-left;
}

.animate.animate-fly-left.ng-hide-add,
.animate.animate-fly-left.ng-leave-active
{
	animation-name: animate-fly-left;
	animation-direction: reverse;
}
/* }}} */
/* .animate-fly-right {{{ */
@keyframes animate-fly-right {
	from {
		transform: translateX(2em);
		opacity: 0;
	}
	to {
		transform: translateX(0);
	}
}

.animate.animate-fly-right.ng-hide-remove,
.animate.animate-fly-right.ng-enter
{
	animation-name: animate-fly-right;
}

.animate.animate-fly-right.ng-hide-add,
.animate.animate-fly-right.ng-leave-active
{
	animation-name: animate-fly-right;
	animation-direction: reverse;
}
/* }}} */
/* .animate-shake {{{ */
/* Shamelessly copied from the excellent https://github.com/daneden/animate.css/blob/master/source/attention_seekers/shake.css */
@keyframes animate-shake {
	from, to {
		transform: translate3d(0, 0, 0);
	}

	10%, 30%, 50%, 70%, 90% {
		transform: translate3d(-10px, 0, 0);
	}

	20%, 40%, 60%, 80% {
		transform: translate3d(10px, 0, 0);
	}
}

.animate.animate-shake {
	animation-name: animate-shake;
}
/* }}} */

/* .animate-reflow-width {{{ */
@keyframes animate-reflow-width {
	from {
		max-width: 0px;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
	to {
		max-width: 300px;
	}
}

.animate.animate-reflow-width {overflow-x: hidden}

.animate.animate-reflow-width.ng-hide-remove,
.animate.animate-reflow-width.ng-enter
{
	animation-name: animate-reflow-width;
}

.animate.animate-reflow-width.ng-hide-add,
.animate.animate-reflow-width.ng-leave-active
{
	animation-name: animate-reflow-width;
	animation-direction: reverse;
}
/* }}} */
/* .animate-reflow-height {{{ */
@keyframes animate-reflow-height {
	from {
		max-height: 0px;
		margin-top: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
	to {
		max-height: 300px;
	}
}

.animate.animate-reflow-height {overflow-y: hidden}

.animate.animate-reflow-height.ng-hide-remove,
.animate.animate-reflow-height.ng-enter
{
	animation-name: animate-reflow-height;
}

.animate.animate-reflow-height.ng-hide-add,
.animate.animate-reflow-height.ng-leave-active
{
	animation-name: animate-reflow-height;
	animation-direction: reverse;
}
/* }}} */
/* .animate-reflow-scale {{{ */
@keyframes animate-reflow-scale {
	from {
		transform: scale(0);
		max-width: 0px;
		max-height: 0px;
		margin: 0;
	}
	to {
		transform: scale(1);
		max-width: 300px;
		max-height: 300px;
	}
}

.animate.animate-reflow-scale.ng-hide-remove,
.animate.animate-reflow-scale.ng-enter
{
	animation-name: animate-reflow-scale;
}

.animate.animate-reflow-scale.ng-hide-add,
.animate.animate-reflow-scale.ng-leave-active
{
	animation-name: animate-reflow-scale;
	animation-direction: reverse;
}
/* }}} */

/* Combinational {{{ */
/* CSS doesn't support multiple animations on the same element so we need to work out each primary + reflow combination here */
/* .animate-fade + .animate-reflow-width {{{ */
.animate.animate-fade.animate-reflow-width.ng-hide-remove,
.animate.animate-fade.animate-reflow-width.ng-enter,
.animate.animate-fade.animate-reflow-width.ng-hide-add,
.animate.animate-fade.animate-reflow-width.ng-leave-active
{animation-name: animate-fade, animate-reflow-width}
/* }}} */
/* .animate-fade + .animate-reflow-height {{{ */
.animate.animate-fade.animate-reflow-height.ng-hide-remove,
.animate.animate-fade.animate-reflow-height.ng-enter,
.animate.animate-fade.animate-reflow-height.ng-hide-add,
.animate.animate-fade.animate-reflow-height.ng-leave-active
{animation-name: animate-fade, animate-reflow-height}
/* }}} */
/* .animate-fade + .animate-reflow-scale {{{ */
.animate.animate-fade.animate-reflow-scale.ng-hide-remove,
.animate.animate-fade.animate-reflow-scale.ng-enter,
.animate.animate-fade.animate-reflow-scale.ng-hide-add,
.animate.animate-fade.animate-reflow-scale.ng-leave-active
{animation-name: animate-fade, animate-reflow-scale}
/* }}} */
/* .animate-fly-up + .animate-reflow-width {{{ */
.animate.animate-fly-up.animate-reflow-width.ng-hide-remove,
.animate.animate-fly-up.animate-reflow-width.ng-enter,
.animate.animate-fly-up.animate-reflow-width.ng-hide-add,
.animate.animate-fly-up.animate-reflow-width.ng-leave-active
{animation-name: animate-fly-up, animate-reflow-width}
/* }}} */
/* .animate-fly-up + .animate-reflow-height {{{ */
.animate.animate-fly-up.animate-reflow-height.ng-hide-remove,
.animate.animate-fly-up.animate-reflow-height.ng-enter,
.animate.animate-fly-up.animate-reflow-height.ng-hide-add,
.animate.animate-fly-up.animate-reflow-height.ng-leave-active
{animation-name: animate-fly-up, animate-reflow-height}
/* }}} */
/* .animate-fly-up + .animate-reflow-scale {{{ */
/* CONFLICT - Cannot scale + fly in at same time */
/* }}} */
/* .animate-fly-down + .animate-reflow-width {{{ */
.animate.animate-fly-down.animate-reflow-width.ng-hide-remove,
.animate.animate-fly-down.animate-reflow-width.ng-enter,
.animate.animate-fly-down.animate-reflow-width.ng-hide-add,
.animate.animate-fly-down.animate-reflow-width.ng-leave-active
{animation-name: animate-fly-down, animate-reflow-width}
/* }}} */
/* .animate-fly-down + .animate-reflow-height {{{ */
.animate.animate-fly-down.animate-reflow-height.ng-hide-remove,
.animate.animate-fly-down.animate-reflow-height.ng-enter,
.animate.animate-fly-down.animate-reflow-height.ng-hide-add,
.animate.animate-fly-down.animate-reflow-height.ng-leave-active
{animation-name: animate-fly-down, animate-reflow-height}
/* }}} */
/* .animate-fly-down + .animate-reflow-scale {{{ */
/* CONFLICT - Cannot scale + fly in at same time */
/* }}} */
/* .animate-fly-left + .animate-reflow-width {{{ */
.animate.animate-fly-left.animate-reflow-width.ng-hide-remove,
.animate.animate-fly-left.animate-reflow-width.ng-enter,
.animate.animate-fly-left.animate-reflow-width.ng-hide-add,
.animate.animate-fly-left.animate-reflow-width.ng-leave-active
{animation-name: animate-fly-left, animate-reflow-width}
/* }}} */
/* .animate-fly-left + .animate-reflow-height {{{ */
.animate.animate-fly-left.animate-reflow-height.ng-hide-remove,
.animate.animate-fly-left.animate-reflow-height.ng-enter,
.animate.animate-fly-left.animate-reflow-height.ng-hide-add,
.animate.animate-fly-left.animate-reflow-height.ng-leave-active
{animation-name: animate-fly-left, animate-reflow-height}
/* }}} */
/* .animate-fly-left + .animate-reflow-scale {{{ */
/* CONFLICT - Cannot scale + fly in at same time */
/* }}} */
/* .animate-fly-right + .animate-reflow-width {{{ */
.animate.animate-fly-right.animate-reflow-width.ng-hide-remove,
.animate.animate-fly-right.animate-reflow-width.ng-enter,
.animate.animate-fly-right.animate-reflow-width.ng-hide-add,
.animate.animate-fly-right.animate-reflow-width.ng-leave-active
{animation-name: animate-fly-right, animate-reflow-width}
/* }}} */
/* .animate-fly-right + .animate-reflow-height {{{ */
.animate.animate-fly-right.animate-reflow-height.ng-hide-remove,
.animate.animate-fly-right.animate-reflow-height.ng-enter,
.animate.animate-fly-right.animate-reflow-height.ng-hide-add,
.animate.animate-fly-right.animate-reflow-height.ng-leave-active
{animation-name: animate-fly-right, animate-reflow-height}
/* }}} */
/* .animate-fly-right + .animate-reflow-scale {{{ */
/* CONFLICT - Cannot scale + fly in at same time */
/* }}} */
/* }}} */
