.ui-toggle {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  cursor: pointer; }

span.ui-toggle-input.disabled + .ui-toggle__track::before {
  background-color: white;
  border-radius: 23px;
  content: '';
  display: block;
  height: calc( 100% + 2px);
  left: -1px;
  top: -1px;
  opacity: 0.5;
  position: absolute;
  width: calc( 100% + 2px);
  z-index: 1; }

.ui-toggle__track {
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  border-radius: 23px;
  box-sizing: border-box;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: background 0.2s 0.05s ease-out, border 0.2s 0.05s ease-in, box-shadow 0.2s ease-in-out;
  width: 100%; }

span.ui-toggle-input:focus + .ui-toggle__track {
  box-shadow: 0 0 0 2px #9ecaed; }

span.ui-toggle-input:active + .ui-toggle__track,
span.ui-toggle-input:hover + .ui-toggle__track {
  box-shadow: none; }

span.ui-toggle-input.checked + .ui-toggle__track {
  background-color: #999;
  border-color: transparent; }

span.ui-toggle-input.disabled + .ui-toggle__track {
  background-color: white; }

.ui-toggle__thumb {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.33);
  content: '';
  height: calc( 100% - 2px);
  left: 1px;
  pointer-events: none;
  position: absolute;
  top: 1px;
  transition: transform 0.2s ease-out; }

span.ui-toggle-input.disabled ~ .ui-toggle__thumb {
  box-shadow: none;
  border: 1px solid #ccc;
  margin: -1px; }

span.ui-toggle-input.checked ~ .ui-toggle__thumb {
  border: 0;
  margin: 0; }

.ui-toggle > span.ui-toggle-input {
  cursor: pointer;
  height: 100%;
  margin: 0;
  opacity: 0;
  vertical-align: top;
  width: 100%; }

.ui-toggle.is-disabled > span.ui-toggle-input {
  pointer-events: none; }

.toggle-primary > span.ui-toggle-input.checked + .ui-toggle__track {
  background-color: #286090; }

.toggle-success > span.ui-toggle-input.checked + .ui-toggle__track {
  background-color: #449d44; }

.toggle-info > span.ui-toggle-input.checked + .ui-toggle__track {
  background-color: #31b0d5; }

.toggle-warning > span.ui-toggle-input.checked + .ui-toggle__track {
  background-color: #f0ad4e; }

.toggle-danger > span.ui-toggle-input.checked + .ui-toggle__track {
  background-color: #d9534f; }

.ui-toggle.toggle-xs {
  height: 22px;
  width: 42px; }

.ui-toggle.toggle-xs > .ui-toggle__thumb {
  width: 20px; }

.ui-toggle.toggle-xs > span.ui-toggle-input.checked ~ .ui-toggle__thumb {
  transform: translateX(20px); }

.ui-toggle.toggle-sm {
  height: 30px;
  width: 52px; }

.ui-toggle.toggle-sm > .ui-toggle__thumb {
  width: 28px; }

.ui-toggle.toggle-sm > span.ui-toggle-input.checked ~ .ui-toggle__thumb {
  transform: translateX(22px); }

.ui-toggle {
  height: 34px;
  width: 60px; }

.ui-toggle > .ui-toggle__thumb {
  width: 32px; }

.ui-toggle > span.ui-toggle-input.checked ~ .ui-toggle__thumb {
  transform: translateX(26px); }

.ui-toggle.toggle-lg {
  height: 46px;
  width: 72px; }

.ui-toggle.toggle-lg > .ui-toggle__thumb {
  width: 44px; }

.ui-toggle.toggle-lg > span.ui-toggle-input.checked ~ .ui-toggle__thumb {
  transform: translateX(26px); }
